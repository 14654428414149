<template>
    <div class="organization_register_sendmail short-container">
        <div class="organization_register_sendmail__top">

            <div class="organization_register_sendmail__top__step">
                <span>
                    ログイン情報入力
                </span>
                <span>
                    企業情報登録
                </span>
                <span>
                    登録内容確認
                </span>
                <span class="active">
                    メール確認
                </span>
                <span>
                    ご利用登録完了
                </span>
            </div>
             <p class="organization_register_sendmail__top-title">
                メール確認
            </p>
        </div>
        <div class="organization_register_sendmail__body">
            <div class="mw-1000">
                <div class="organization_register_sendmail__body__form">
                    <form class="horizontal">
                        <div
                            class="organization_register_sendmail__body__form-infomation"
                        >
                            ご登録のアドレスにメールを送信しました。<br>
                            <br>
                            内容をご確認いただき、メール内のURLを48時間以内にクリックしてください。<br>
                            48時間を過ぎますと登録が無効になる場合があります。
                        </div>
                        <div class="btn_group">
                            <button
                                @click="gotoTop"
                                type="button"
                                class="btn-md btn-blue shadow"
                            >
                                TOPに戻る
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <Alert
          v-if="state.showAlertPopup == true"
          :title="state.alertContent.title"
          :content="state.alertContent.content"
          @close="state.showAlertPopup = false"
        />
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/register/send-mail/SendMail.ts"></script>

<style lang="scss">
@import "@/presentation/views/organization/register/send-mail/SendMail.scss";
</style>
