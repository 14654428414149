import { reactive, defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import RoutePath from "@/commons/RoutePath";
import OrganizationRegisterInteractor from "@/domain/usecases/OrganizationRegisterInteractor";
import DIContainer from "@/core/DIContainer";
import { useStore } from "vuex";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
export default defineComponent({
    name: "OrganizationRegisterSendMail",
    components: {
        Breadcrumb: Breadcrumb,
        Alert: Alert
    },
    setup: function () {
        var router = useRouter();
        var route = useRoute();
        var store = useStore();
        var organization_member_id = route.query
            .organization_member_id;
        var organization_id = route.query.organization_id;
        var organizationRegisterInteractor = DIContainer.instance.get(OrganizationRegisterInteractor);
        var state = reactive({
            listBreadcrumb: [
                {
                    route: '/', name: "TOP"
                },
                {
                    route: '', name: "メールのご確認"
                }
            ],
            showAlertPopup: false,
            alertContent: {
                title: "",
                content: ""
            },
            isSending: false
        });
        function gotoTop() {
            store.commit("setHeaderMode", "default");
            router.push(RoutePath.TOP);
        }
        function resendEmail() {
            if (state.isSending == true) {
                return;
            }
            state.isSending = true;
            organizationRegisterInteractor
                .resendEmail(organization_member_id, organization_id)
                .then(function (result) {
                state.isSending = false;
                state.showAlertPopup = true;
                state.alertContent = {
                    title: "",
                    content: "メールを再送信しました。ご確認ください。"
                };
                return;
            }).catch(function (error) {
                state.isSending = false;
            });
        }
        return {
            state: state,
            gotoTop: gotoTop,
            resendEmail: resendEmail
        };
    }
});
